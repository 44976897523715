import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setEmail } from 'store/reducers/header';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    CardContent,
    ClickAwayListener,
    Grid,
    IconButton,
    List, 
    ListItemButton, 
    ListItemIcon, 
    ListItemText,
    Paper,
    Popper,
    Stack,
    Tab,
    Tabs,
    Typography, 
    Link
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

// assets
import avatar1 from 'assets/images/users/avatar-1.png';
import { EditOutlined, ProfileOutlined, LogoutOutlined, UserOutlined, WalletOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
// import Link from 'themes/overrides/Link';
// redux
import { setProfileColor } from 'store/reducers/header';
import { GLOBAL_URL } from '../../../../config';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `profile-tab-${index}`,
        'aria-controls': `profile-tabpanel-${index}`
    };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = (props) => {    
    const theme = useTheme();
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [avatar, setAvatar] = useState("");

    const dispatch = useDispatch();
    const { firstName, lastName, profileColor, email, activities, userName } = useSelector((state) => state.header);
    //logout
    const handleLogout = async () => {
        axios({
            method: "POST",
            url: GLOBAL_URL+"/api/logoutToken",
            data: {
                userName: userName
            }
        })
        .then((response) => {
            props.removeToken() // Delete the users token
            dispatch({type: "LOG_OUT"}) // Clear all the redux values when logging out
            navigate("/")
        }).catch((error) => {
            if(error.response) {
                console.log(error.response)
                console.log(error.response.status)
                console.log(error.response.headers)
            }
        })
    };

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const iconBackColorOpen = 'grey.300';

    function stringToColor(string) {
        let hash = 0;
        let i;
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
        // save to redux
        dispatch(setProfileColor({profileColor: color}))
        return color;
    }

    useEffect(() => {
        setAvatar(stringAvatar(firstName, lastName))
    }, [])
    
      
    function stringAvatar(firstName, lastName) {
        var name = `${firstName} ${lastName}`
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: 32, 
                height: 32,
                fontSize: '16px'
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    // const handleSetup = (event) => {
    //     axios({
    //         method: "POST",
    //         url: GLOBAL_URL+"/api/getEmailHash",
    //         headers: {
    //             Authorization: 'Bearer ' + props.token
    //         }
    //     })
    //     .then((response) => {
    //         navigate('/twofactor/setup', { state: {
    //             emailHash: response.data.emailHash,
    //         }});
    //     })
    //     .catch((error) => {
    //         console.log(error.response)
    //         console.log(error.response.status)
    //         console.log(error.response.headers)
    //     })
    // }

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <ButtonBase
                sx={{
                    p: 0.25,
                    bgcolor: open ? iconBackColorOpen : 'transparent',
                    borderRadius: 25,
                    '&:hover': { bgcolor: 'secondary.lighter' }
                }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                    <Avatar {...avatar} />
                </Stack>
            </ButtonBase>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        {open && (
                            <Paper
                                sx={{
                                    boxShadow: theme.customShadows.z1,
                                    width: 290,
                                    minWidth: 240,
                                    maxWidth: 290,
                                    [theme.breakpoints.down('md')]: {
                                        maxWidth: 250
                                    }
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MainCard elevation={0} border={false} content={false}>
                                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Stack direction="row" spacing={1.25} alignItems="center">
                                                        <Avatar {...stringAvatar(firstName, lastName)} />
                                                        <Stack>
                                                            <Typography variant="h6">{firstName} {lastName}</Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {userName} ({email})
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
                                            {(activities.indexOf('/profile') !==  -1) &&  <ListItemButton selected={selectedIndex === 1} component={Link} to={"/profile"}>
                                                <ListItemIcon>
                                                    <UserOutlined />
                                                </ListItemIcon>
                                                <ListItemText primary="Manage Profile" />
                                            </ListItemButton>}
                                            {(activities.indexOf('/resetpass') !==  -1) && <ListItemButton selected={selectedIndex === 3} component={Link} to={"/resetpass"}>
                                                <ListItemIcon>
                                                    <LockOutlined />
                                                </ListItemIcon>
                                                <ListItemText primary="Reset Password" />
                                            </ListItemButton>}
                                            {(activities.indexOf('/api/logoutToken') !==  -1) && <ListItemButton selected={selectedIndex === 4} onClick={handleLogout}>
                                                <ListItemIcon>
                                                    <LogoutOutlined />
                                                </ListItemIcon>
                                                <ListItemText primary="Logout" />
                                            </ListItemButton>}
                                        </List>
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default Profile;
