import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import { EditOutlined } from '@ant-design/icons';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

// icons
const icons = {
    AccountTreeIcon,
    DeveloperBoardIcon,
    EditOutlined,
    FormatListBulletedIcon
};
// ==============================|| MENU ITEMS - Monitor ||============================== //

const monitor = {
    id: 'group-monitor',
    title: 'Resource Monitor',
    type: 'group',
    children: [
        {
            id: 'grid',
            title: 'Grid Monitor',
            type: 'item',
            url: '/gridMonitor',
            icon: icons.AccountTreeIcon,
            breadcrumbs: false
        },
        {
            id: 'process',
            title: 'Process Monitor',
            type: 'item',
            url: '/processMonitor',
            icon: icons.DeveloperBoardIcon,
            breadcrumbs: false
        },
        {
            id: 'analysisSchedule',
            title: 'Process Scheduler',
            type: 'item',
            url: '/processSchedule',
            icon: icons.FormatListBulletedIcon,
            breadcrumbs: false
        },
        {
            id: 'serverCPU',
            title: 'Server Monitor',
            type: 'item',
            url: '/serverMonitor',
            icon: icons.AccountTreeIcon,
            breadcrumbs: false
        },
        {
            id: 'serverConfiguration',
            title: 'Server Monitor Config',
            type: 'adminItem',
            url: '/admin/serverMonitorConfig',
            icon: icons.EditOutlined,
            breadcrumbs: false
        }
        
    ]
};


export default monitor;