import ThemeCustomization from 'themes';
import Routes from 'routes';
import ScrollTop from 'components/ScrollTop';
import 'App.css';

/*
In the App function, the value object returned when the useToken function is called is destructured 
and the values are assigned to the token, removeToken and setToken variables respectively.

//const { token, removeToken, setToken } = useToken();

Next, the BrowserRouter function is made the parent component and in it, the Header component is placed 
with the removeToken function passed as an argument which is called prop in react.

//<Header token={removeToken}/>

Then the javascript conditional ternary operator is used to ensure that the user must have a token before 
having access to the profile component. If the user doesn't have a token, the Login component is rendered 
with the setToken function passed as an argument. Else if the user already has a token, the Profile 
component with the URL path /profile is rendered and displayed to the user.

Read more on how to use React Router for more information
*/

function App() {
  return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  );
}

export default App;