// project import
import workstation from './workstation';
import dashboard from './dashboard';
import utilities from './utilities';
import support from './support';
import monitor from './monitor';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, monitor, support, utilities]
};

export default menuItems;
