// third-party
import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

// import logger from 'redux-logger'; use logger in middleware to debug redux
import thunk from 'redux-thunk';

// project import
import reducers from './reducers';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
    reducer: reducers,
    middleware: [thunk],
});
const persistor = persistStore(store);
const { dispatch } = store;


export { store, dispatch, persistor };
