// types
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    currTab: 1,
    keyValues: { //Initialized keys for each instance of the terminal, each value must be unique
        '1': 0,
        '2': 2,
        '3': 4,
        '4': 6,
        '5': 8,
    }, 
};

// ==============================|| SLICE - HEADER ||============================== //

const terminal = createSlice({
    name: 'terminal',
    initialState,
    reducers: {
        setCurrTab(state, action){
            state.currTab = action.payload.currTab
        },
        setKeyValues(state, action) {
            const newKeyValues = action.payload;
            state.keyValues = { ...state.keyValues, ...newKeyValues };
        }
    }
});

export default terminal.reducer;

export const { setCurrTab, setKeyValues } = terminal.actions;