import { useState } from 'react';

/*
The getToken function is used to retrieve the token stored in the localStorage and only returns a token 
if it exists hence the use of the && conditional operator.

The useState hook is used to handle the state of the token variable which will contain the value of the 
token. This ensures that the react application always reloads when any of the functions are called. 
Such that when a user logs in and the token is stored or when the user logs out, the application also 
becomes aware that a change has occurred in the web storage of your browser and hence reacts accordingly 
by either redirecting to the page the user wants to access or returning to the login page once the user 
logs out.

The saveToken function handles the storage of the token obtained when the user logs in and the setToken 
function in it updates the state of the token variable with the token passed as an argument to the 
saveToken function.

The removeToken function deletes the token from the local storage and returns the token back to the null 
state whenever it gets called.

Finally, the saveToken function assigned as a value to the setToken variable, the value of the token 
itself and the removeToken function are all returned as the result of calling the useToken function.
*/

function useToken() {
  //This will get the user token
  function getToken() {
    const userToken = localStorage.getItem('token');
    return userToken && userToken
  }

  const [token, setToken] = useState(getToken());
  
  //This will save the user token
  function saveToken(userToken) {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  //This will remove the user token
  function removeToken() {
    localStorage.removeItem("token");
    setToken(null);
  }

  return {
    setToken: saveToken,
    token,
    removeToken
  }

}

export default useToken;
