// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useSelector } from 'react-redux';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const { admin } = useSelector((state) => state.header);
    const navGroups = menuItem.items.map((item) => {
        switch(item.id) {
            case 'admin':
                if(!admin){
                    return
                }
                else{
                    return <NavGroup key={item.id} item={item} />;
                }
            default:
                return <NavGroup key={item.id} item={item} />;
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
