// third-party
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// project import
import menu from './menu';
import header from './header';
import checkout from './checkout';
import pricing from './pricing';
import fileManager from './fileManager'
import fileManagerPopUp from './fileManagerPopUp'
import terminal from './terminal'
import analysisScheduler from './analysisScheduler';

// ==============================|| COMBINE REDUCERS ||============================== //

const appReducer = combineReducers({
    menu,
    header,
    checkout,
    pricing,
    fileManager,
    fileManagerPopUp,
    terminal,
    analysisScheduler

});

const rootReducer = ( state, action ) => {
    if( action.type == 'LOG_OUT' ) {
        storage.removeItem('persist:root')
        state = undefined
    }
    return appReducer(state, action)
}

const persistConfig = {
    key: 'root',
    storage,
}
const reducers = persistReducer(persistConfig, rootReducer)

export default reducers;
