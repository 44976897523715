// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    selection: '',
    fileSelection: [],
    selectionType: null,
    selectionName: null,
    selectedRowID: null,
    emptyMsg: '',
    lastPath: '',
    responseMsg: null,
    fileAlertSeverity: "error",
    fileAlertTimeout: false,
    dirManager: {},
    currentPathRedux: ''
};

// ==============================|| SLICE - HEADER ||============================== //

const fileManagerPopUp = createSlice({
    name: 'fileManagerPopUp',
    initialState,
    reducers: {
        setSelectionPopUp(state, action){
            state.selection = action.payload.selection
        },
        setFileSelectionPopUp(state, action){
            state.fileSelection = action.payload.fileSelection
        },
        setSelectionTypePopUp(state, action){
            state.selectionType = action.payload.selectionType
        },
        setSelectionNamePopUp(state, action){
            state.selectionName = action.payload.selectionName
        },
        setSelectedRowIDPopUp(state, action){
            state.selectedRowID = action.payload.selectedRowID
        },
        setEmptyMsgPopUp(state, action){
            state.emptyMsg = action.payload.emptyMsg
        },
        setLastPathPopUp(state, action){
            state.lastPath = action.payload.lastPath
        },
        setResponseMsgPopUp(state, action){
            state.responseMsg = action.payload.responseMsg
        },
        setFileAlertSeverityPopUp(state, action){
            state.fileAlertSeverity = action.payload.fileAlertSeverity
        },
        setFileAlertTimeoutPopUp(state, action){
            state.fileAlertTimeout = action.payload.fileAlertTimeout
        },
        setDirManagerPopUp(state, action){
            state.dirManager = action.payload.dirManager
        },
        setCurrentPathReduxPopUp(state, action){
            state.currentPathRedux = action.payload.currentPathRedux
        },
        setToggleRefreshPopUp(state){
            state.toggleRefresh = !state.toggleRefresh
        }
    }
});

export default fileManagerPopUp.reducer;

export const { setSelectionPopUp, setFileSelectionPopUp, setSelectionTypePopUp, setSelectionNamePopUp, setSelectedRowIDPopUp, 
               setEmptyMsgPopUp, setLastPathPopUp, setResponseMsgPopUp, setFileAlertSeverityPopUp, setFileAlertTimeoutPopUp, 
               setDirManagerPopUp, setCurrentPathReduxPopUp, setToggleRefreshPopUp } = fileManagerPopUp.actions;