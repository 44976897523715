// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    openItem: ['dashboard'],
    openComponent: 'buttons',
    drawerOpen: false,
    componentDrawerOpen: true,
    // Checkout.js and AddressForm.js
    nextOpen: 8,
    countryTT: false,
    stateTT: false,
    cityTT: false,
    firstNameTT: false,
    lastNameTT: false,
    address1TT: false,
    zipTT: false,
    exists_firstName: false, 
    exists_lastName: false, 
    exists_address1: false, 
    exists_zip: false,
    isCountry: false,
    isState: false,
    isCity: false,
    saveContent: false
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.openItem = action.payload.openItem;
        },
        activeComponent(state, action) {
            state.openComponent = action.payload.openComponent;
        },
        openDrawer(state, action) {
            state.drawerOpen = action.payload.drawerOpen;
        },
        openComponentDrawer(state, action) {
            state.componentDrawerOpen = action.payload.componentDrawerOpen;
        },

        openNext(state, action) {
            state.nextOpen = action.payload.nextOpen;
        },
        TTcountry(state, action) {
            state.countryTT = action.payload.countryTT;
        },
        TTstate(state, action) {
            state.stateTT = action.payload.stateTT;
        },
        TTcity(state, action) {
            state.cityTT = action.payload.cityTT;
        },
        TTfirstName(state, action) {
            state.firstNameTT = action.payload.firstNameTT;
        },
        TTlastName(state, action) {
            state.lastNameTT = action.payload.lastNameTT;
        },
        TTaddress1(state, action) {
            state.address1TT = action.payload.address1TT;
        },
        TTzip(state, action) {
            state.zipTT = action.payload.zipTT;
        },
        firstName_exists(state, action) {
            state.exists_firstName = action.payload.exists_firstName;
        },
        lastName_exists(state, action) {
            state.exists_lastName = action.payload.exists_lastName;
        },
        address1_exists(state, action) {
            state.exists_address1 = action.payload.exists_address1;
        },
        zip_exists(state, action) {
            state.exists_zip = action.payload.exists_zip;
        },
        countryIs(state, action) {
            state.isCountry = action.payload.isCountry;
        },
        stateIs(state, action) {
            state.isState = action.payload.isState;
        },
        cityIs(state, action) {
            state.isCity = action.payload.isCity;
        },
        contentSave(state, action) {
            state.saveContent = action.payload.saveContent;
        }

    }
});

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer, openNext, TTcountry, TTstate, TTcity, TTfirstName, TTlastName, TTaddress1, TTzip, firstName_exists, lastName_exists, address1_exists, zip_exists, countryIs, stateIs, cityIs, contentSave } = menu.actions;
