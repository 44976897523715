import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material-ui
import { Box, List, Typography } from '@mui/material';

// project import
import NavItem from './NavItem';

import { drawerTextDivider } from 'config';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const menu = useSelector((state) => state.menu);
    const { drawerOpen } = menu;
    
    const { admin, activities } = useSelector((state) => state.header);

    const navCollapse = item.children?.map((menuItem) => {
        switch(menuItem.type) {
            case 'item':
                if(activities.indexOf(menuItem.url) !== -1) {
                    return <NavItem key={menuItem.id} item={menuItem} level={1} />;
                } else {
                    return
                }
            case 'adminItem':
                if(admin){
                    if(activities.indexOf(menuItem.url) !== -1) {
                        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
                    } else {
                        return
                    }
                }
        }
    });
    return (
        <>
        {navCollapse.every(i => typeof i === 'undefined') ? (
            <></>
        ):(
            <List
            subheader={
                item.title &&
                drawerOpen && (
                    <Box sx={{ pl: 0, mb: 1.5 }}>
                        <Typography variant="subtitle2" color="textSecondary">
                            {drawerTextDivider ?  '-----------' : item.title}
                        </Typography>
                        {/* only available in paid version */}
                    </Box>
                )
            }
            sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
            >
                {navCollapse}
            </List>
        )}
        </>

    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
