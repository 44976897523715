import { useRoutes } from 'react-router-dom';
import useToken from '../utils/useToken';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { token, removeToken, setToken } = useToken();
    return (
        <div className="App">
            {!token && token !== "" && token !== undefined ? (
                <>
                    <LoginRoutes setToken={setToken}/>
                </>
            ):(
                <>
                    <MainRoutes token={token} setToken={setToken} removeToken={removeToken}/>
                </>
            )}
        </div>
    );
}