// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    userName: "UserName",
    firstName: "FirstName",
    lastName: 'LastName',
    email: "email@securegen.com",
    admin: false,
    profileColor: '000000',
    // organization is set at the time of sign in
    organization: '',
    organizationIndex: -1,
    // page loads bassed off of current organization
    currOrganization: 'ALL',
    notifications: [],
    activities: [],
    tokenExpired: false,
    SMLPublicKey: '',
    sonicPublicKey: ''
};

// ============================|| SLICE - HEADER ||============================== //

const header = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setUserName(state, action) {
            state.userName = action.payload.userName;
        },
        setFirstName(state, action) {
            state.firstName = action.payload.firstName;
        },
        setLastName(state, action) {
            state.lastName = action.payload.lastName;
        },
        setEmail(state, action) {
            state.email = action.payload.email;
        },
        setAdmin(state, action) {
            state.admin = action.payload.admin;
        },
        setProfileColor(state, action) {
            state.profileColor = action.payload.profileColor;
        },
        setOrganization(state, action) {
            state.organization = action.payload.organization;
        },
        setCurrOrganization(state, action) {
            state.currOrganization = action.payload.currOrganization;
        },
        setOrganizationIndex(state, action) {
            state.organizationIndex = action.payload.organizationIndex;
        },
        setNotifications(state, action){
            state.notifications = [action.payload.notifications, ...state.notifications];
        }, 
        setActivities(state,action) {
            state.activities = action.payload.activities
        },
        setTokenExpired(state, action){
            state.tokenExpired = action.payload.tokenExpired
        },
        setSMLPublicKey(state, action){
            state.SMLPublicKey = action.payload.SMLPublicKey
        },
        setSonicPublicKey(state, action){
            state.sonicPublicKey = action.payload.sonicPublicKey
        },
    }
});

export default header.reducer;

export const { setUserName, setFirstName, setLastName, setEmail, setAdmin, setProfileColor, setOrganization, setOrganizationIndex,
    setNotifications, setActivities, setTokenExpired, setCurrOrganization, setSMLPublicKey, setSonicPublicKey } = header.actions;
