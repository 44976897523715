import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Toolbar, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { makeStyles } from '@material-ui/core/styles';

// project import
import AppBarStyled from '../Header/AppBarStyled';

// assets
import Box from '@mui/material/Box';


// ==============================|| MAIN LAYOUT - FOOTER ||============================== //
const useStyles = makeStyles((theme) => ({
    footer: {
      backgroundColor: '#0a093e',
    //   padding: theme.spacing(1),
      textAlign: 'center',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      zIndex: '1202',
    },
  }));

const Footer = (props) => {
    const theme = useTheme();
    const classes = useStyles();


    // content
    const mainFooterStyled = (
        <Toolbar sx={{minHeight: '25px !important', p:0}}>
            <Typography color="inherit" noWrap sx={{ color: '#dddddd', mr: '5px', fontSize: '11px' }} align="left">
                © 2023
            </Typography> 
            <Link href="/dashboard">
                <Typography color="inherit" noWrap sx={{ color: '#4154f1', fontSize: '11px' }} align="left">
                    SECUREGEN
                </Typography> 
            </Link>

            <Box sx={{gap: '20px', display: 'flex', mr: '20px', ml:'auto'}}>
            <Typography color="inherit" noWrap sx={{ color: '#dddddd', fontSize: '11px' }} align="left">
                SecureGen Platform Version 1.0.1 (build 24.01.26)
            </Typography> 
            </Box>
        </Toolbar>
    );

    return (
        <>
        <footer className={classes.footer}>
            {mainFooterStyled}
        </footer>
        </>
    );
};

Footer.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
};

export default Footer;
