// import { GLOBAL_URL, TOKEN_EXPIRE_MESSAGE } from '../config';
import { useSelector, useDispatch } from 'react-redux';
// import React, { useState, useEffect } from 'react';
import { setEmail, setFirstName, setLastName, setOrganization, setOrganizationIndex, setAdmin, setActivities } from 'store/reducers/header';
// import axios from 'axios';

function AfterRequest(){
    const { organization,activities,email,admin,firstName,lastName} = useSelector((state) => state.header);
    const dispatch = useDispatch();

    function setNewToken(props,newToken){
        // console.log(props,newToken)
        if (newToken){
            console.log("Updating Token")
            props.setToken(newToken)
        }
    }

    function updateUserData(newUserData){

        if (newUserData.organization != organization){
            console.log("Updating organization")
            dispatch(setOrganization({ organization: newUserData.organization }));
            // dispatch(setOrganizationIndex({ organizationIndex: newUserData.organizationIndex }));
        }
        if (newUserData.admin != admin){
            console.log("Updating admin")
            dispatch(setAdmin({ admin: newUserData.admin }));
        }
        if (newUserData.firstName == firstName){
            console.log("Updating firstName")
            dispatch(setFirstName({ firstName: "newUserData.firstName" }));
        }
        if (newUserData.lastName != lastName){
            console.log("Updating lastName")
            dispatch(setLastName({ lastName: newUserData.lastName }));
        }
        if (newUserData.activities != activities ){
            console.log("Updating activities")
            // console.log('activities', activities)
            // console.log('newUserData.activities', newUserData.activities)
            dispatch(setActivities({ activities: newUserData.activities }));
        }
        if (newUserData.email != email){
            console.log("Updating email")
            dispatch(setEmail({ email: newUserData.email }));
        }
    }
   
    return {
        setNewToken,
        updateUserData
  }
    
    // return (<></>)
}

export default AfterRequest;