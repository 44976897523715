// assets
import { 
    DashboardOutlined,
    EditOutlined
 } from '@ant-design/icons';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

// icons
const icons = {
    DashboardOutlined,
    WorkspacesIcon,
    EditOutlined
};
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-dashboard',
    title: 'Main',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.DashboardOutlined,
            breadcrumbs: false
        },
        {
            id: 'admin-dashboardConfig',
            title: 'Dashboard Configurations',
            type: 'adminItem',
            url: '/admin/DashboardConfig',
            icon: icons.EditOutlined,
            breadcrumbs: false
        },
        {
            id: 'console',
            title: 'Console',
            type: 'item',
            url: '/console',
            icon: icons.WorkspacesIcon,
            breadcrumbs: false
        }
    ]
};


export default dashboard;
