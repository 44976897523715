import pages from 'menu-items/workstation';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';
//import PricingAdmin from '../pages/PricingAdmin';

// render - login
const VerifySignIn = Loadable(lazy(() => import('../pages/VerifySignIn')));
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Console = Loadable(lazy(() => import('../pages/Console')));
const AnalysisSchedule = Loadable(lazy(() => import('../pages/AnalysisSchedule')));
const AnalysisConsole = Loadable(lazy(() => import('../pages/AnalysisConsole')));
const Profile = Loadable(lazy(() => import('../pages/Profile')));
const NewWorkstation = Loadable(lazy(() => import('../pages/NewWorkstation')));
const MyWorkstations = Loadable(lazy(() => import('../pages/MyWorkstation')));
const WorkstationConfig = Loadable(lazy(() => import('../pages/WorkstationConfig')));
const AwsConfigurations = Loadable(lazy(() => import('../pages/AwsConfigurations')));
const PricingAdmin = Loadable(lazy(() => import('../pages/PricingAdmin')));
const AuditTrail = Loadable(lazy(() => import('../pages/AuditTrail')));
const ResetPasswordAlt = Loadable(lazy(() => import('../pages/ResetPasswordAlt')));
const VerifyEmail = Loadable(lazy(() => import('../pages/VerifyEmail')));
const UserPortal = Loadable(lazy(() => import('../pages/UserPortal')));
const FeaturesConfig = Loadable(lazy(() => import('../pages/FeaturesConfig')));
const BillingStation = Loadable(lazy(() => import('../pages/BillingStation')));
const Documentation = Loadable(lazy(() => import('../pages/Documentation.js')));
const DocumentationConfig = Loadable(lazy(() => import('../pages/DocumentationConfig.js')));
const TwoFactorSetUp = Loadable(lazy(() => import('../pages/TwoFactorSetUp')));
const TwoFactorVerifyLink = Loadable(lazy(() => import('../pages/TwoFactorVerifyLink')));
const TwoFactorCheck = Loadable(lazy(() => import('../pages/TwoFactorCheck')));
const TwoFactorAuth = Loadable(lazy(() => import('../pages/TwoFactorAuth')));
const EditHome = Loadable(lazy(() => import('../pages/EditHome')));
const QStat = Loadable(lazy(() => import('../pages/QStat')));
const GridMonitor = Loadable(lazy(() => import('../pages/GridMonitor')));
const ProcessMonitor = Loadable(lazy(() => import('../pages/ProcessMonitor')));
const ServerMonitorConfig = Loadable(lazy(() => import('../pages/ServerMonitorConfig')));
const DashboardConfig = Loadable(lazy(() => import('../pages/DashboardConfig')));
const ActivitiesMain = Loadable(lazy(() => import('../pages/ActivitiesMain')));
const Executables = Loadable(lazy(() => import('../pages/Executables')));
const ServerMonitor = Loadable(lazy(() => import('../pages/ServerMonitor')));
const UserGuide = Loadable(lazy(() => import('../pages/UserGuide')));
const AddProject = Loadable(lazy(() => import('../pages/AddProject')));
const RequestForms = Loadable(lazy(() => import('../pages/RequestForms')));
const AuthorizeSignIn = Loadable(lazy(() => import('../pages/AuthorizeSignIn')));
const NoAccess = Loadable(lazy(() => import('../pages/NoAccess')));


// ==============================|| MAIN ROUTING ||============================== //

function MainRoutes(props) {
    const { admin, activities } = useSelector((state) => state.header);
    //...props = props.token, props.setToken, props.removeToken
    return (
        <Routes>
            <Route path="/" element={<MainLayout token={props.token} setToken={props.setToken} removeToken={props.removeToken} isAdmin={admin}/>}>
                <Route exact path="/" element={<Dashboard {...props} />}></Route>
                {(activities.indexOf('/verifysignin') !==  -1) && (<Route exact path="/verifysignin" element={<VerifySignIn {...props} />}></Route>) }
                {(activities.indexOf('/dashboard') !==  -1) && (<Route exact path="/dashboard" element={<Dashboard {...props} />}></Route>)}
                {(activities.indexOf('/console') !==  -1) && (<Route exact path="/console" element={<Console {...props} popUp={false}/>}></Route>)}
                {(activities.indexOf('/analysisSchedule') !==  -1) && (<Route exact path="/processSchedule" element={<AnalysisConsole {...props} />}></Route>)}
                {(activities.indexOf('/newWorkstation') !==  -1) && (<Route exact path="/newWorkstation" element={<NewWorkstation {...props} />}></Route>)}
                {(activities.indexOf('/myWorkstations') !==  -1) && (<Route exact path="/myWorkstations" element={<MyWorkstations {...props} />}></Route>)}
                {(activities.indexOf('/billing') !==  -1) && (<Route exact path="/billing" element={<BillingStation {...props}/>}></Route>)}
                {(activities.indexOf('/documentation') !==  -1) && (<Route exact path="/documentation" element={<Documentation {...props}/>}></Route>)}
                {(activities.indexOf('/profile') !==  -1) && (<Route exact path="/profile" element={<Profile {...props}/>}></Route>)}
                {(activities.indexOf('/auditTrail') !==  -1) && (<Route exact path="/auditTrail" element={<AuditTrail {...props}/>}></Route>)}
                {(activities.indexOf('/userGuide') !==  -1) && (<Route exact path="/userGuide" element={<UserGuide {...props}/>}></Route>)}
                {(activities.indexOf('/addProject') !==  -1) && (<Route exact path="/addProject" element={<AddProject {...props}/>}></Route>)}
                {(activities.indexOf('/qStat') !==  -1) && (<Route exact path="/qStat" element={<QStat {...props}/>}></Route>)}
                {(activities.indexOf('/gridMonitor') !==  -1) && (<Route exact path="/gridMonitor" element={<GridMonitor {...props}/>}></Route>)}
                {(activities.indexOf('/processMonitor') !==  -1) && (<Route exact path="/processMonitor" element={<ProcessMonitor {...props}/>}></Route>)}
                {(activities.indexOf('/serverMonitor') !== -1) && (<Route exact path="/serverMonitor" element={<ServerMonitor {...props}/>}></Route>)}
                {(activities.indexOf('/requestForms') !==  -1) && (<Route exact path="/requestForms" element={<RequestForms {...props}/>}></Route>)}
                {(<Route exact path="/authorizesignin" element={<AuthorizeSignIn {...props}/>}></Route>)}
                {admin && (
                    <Route>
                        {(activities.indexOf('/admin/users') !==  -1) && <Route exact path="/admin/users" element={<UserPortal token={props.token} setToken={props.setToken} removeToken={props.removeToken} />}></Route>}
                        {(activities.indexOf('/admin/workConfig') !==  -1) && <Route exact path="/admin/workConfig" element={<WorkstationConfig token={props.token} setToken={props.setToken} removeToken={props.removeToken} />}></Route>}
                        {(activities.indexOf('/pricingAdmin') !==  -1) && <Route exact path="/pricingAdmin" element={<PricingAdmin token={props.token} setToken={props.setToken} removeToken={props.removeToken}/>}></Route>}
                        {(activities.indexOf('/admin/AwsConfigurations') !==  -1) && <Route exact path="/admin/AwsConfigurations" element={<AwsConfigurations token={props.token} setToken={props.setToken} removeToken={props.removeToken}/>}></Route>}
                        {(activities.indexOf('/admin/featuresConfig') !==  -1) && <Route exact path="/admin/featuresConfig" element={<FeaturesConfig token={props.token} setToken={props.setToken} removeToken={props.removeToken} />}></Route>}
                        {(activities.indexOf('/admin/EditHome') !==  -1) && <Route exact path="/admin/EditHome" element={<EditHome token={props.token} setToken={props.setToken} removeToken={props.removeToken} />}></Route>}
                        {(activities.indexOf('/admin/DashboardConfig') !==  -1) && <Route exact path="/admin/DashboardConfig" element={<DashboardConfig token={props.token} setToken={props.setToken} removeToken={props.removeToken} />}></Route>}
                        {(activities.indexOf('/admin/activitiesConfig') !==  -1) && <Route exact path="/admin/activitiesConfig" element={<ActivitiesMain {...props}/>}></Route>}
                        {(activities.indexOf('/admin/executables') !==  -1) && <Route exact path="/admin/executables" element={<Executables {...props}/>}></Route>}
                        {(activities.indexOf('/admin/serverMonitorConfig') !== -1) && <Route exact path="/admin/serverMonitorConfig" element={<ServerMonitorConfig token={props.token} setToken={props.setToken} removeToken={props.removeToken}/>}></Route>}
                        {(activities.indexOf('/admin/documentationConfig') !==  -1) && (<Route exact path="/admin/documentationConfig" element={<DocumentationConfig {...props}/>}></Route>)}
                    </Route>
                )}
            </Route>
            {activities.indexOf('/twofactor') !==  -1 && (<Route exact path="/twofactor" element={<TwoFactorVerifyLink {...props} />}></Route>)}
            {activities.indexOf('/twofactor/setup') !==  -1 && (<Route exact path="/twofactor/setup" element={<TwoFactorSetUp {...props} />}></Route>)}
            {activities.indexOf('/twofactor/check') !==  -1 && (<Route exact path="/twofactor/check" element={<TwoFactorCheck {...props} />}></Route>)}
            {activities.indexOf('/twofactor/auth') !==  -1 && (<Route exact path="/twofactor/auth" element={<TwoFactorAuth {...props} />}></Route>)}
            {activities.indexOf('/resetpass') !==  -1 && (<Route exact path="/resetpass" element={<ResetPasswordAlt {...props} />}></Route>)}
            {activities.indexOf('/verifyaccount') !==  -1 && (<Route exact path="/verifyaccount" element={<VerifyEmail {...props} />}></Route>)}
            {/* 👇️ MUST BE SPECIFIED LAST IN THE LIST OF ROUTE components 👇️ */}
            <Route path="*" element={<NoAccess />} />
        </Routes>
    )
};

export default MainRoutes;