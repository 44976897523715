// types
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { GLOBAL_URL } from '../../config';

// initial state
const initialState = {
    jsonData: [],
    header: "",
    pageDesc: "",
    recentlyEditedDescription: [],
    status: 'idle',
    error: null
};

export const fetchPosts = createAsyncThunk('posts/fetchPosts',   async () => {
  try {
    console.log("bbbb")
    const response = await axios.get(GLOBAL_URL+"/api/get_pricing_json")
    return response.data
  } catch (err) {
    return err
  }
}
  );

const pricing = createSlice({
    name: 'pricing',
    initialState,
    reducers: {
        activeDescription(state, action) {
            state.recentlyEditedDescription = action.payload.recentlyEditedDescription;
        }
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchPosts.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(fetchPosts.fulfilled, (state, action) => {
            state.status = 'succeeded'
            // Add any fetched posts to the array

            state.jsonData = action.payload.tiers
            state.header = action.payload.header
            state.pageDesc = action.payload.page_desc

          })
        //   .addCase(fetchPosts.rejected, (state, action) => {
        //     // console.log("action.error")
        //     // console.log(action.error)
        //     state.status = 'failed'
        //     state.error = action.error.message
        //   })
      }
});

export default pricing.reducer;

export const selectAllJSONData = state => state.pricing.jsonData

export const { activeDescription} = pricing.actions;