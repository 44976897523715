import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

// project import
import Loadable from '../components/Loadable';
import MinimalLayout from '../layout/MinimalLayout';

// render - login
const Home = Loadable(lazy(() => import('../pages/Home')));
const AutoSignIn = Loadable(lazy(() => import('../pages/AutoSignIn')));
const ErrPage = Loadable(lazy(() => import('../pages/ErrPage')));
const SignIn = Loadable(lazy(() => import('../pages/SignIn')));
const SignUp = Loadable(lazy(() => import('../pages/SignUp')));
//const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const ChangePassword = Loadable(lazy(() => import('../pages/ChangePassword')));
//const Checkout = Loadable(lazy(() => import('../pages/Checkout')));
const CheckEmail = Loadable(lazy(() => import('../pages/CheckEmail')));
const AccountLocked = Loadable(lazy(() => import('../pages/AccountLocked')));
const VerifyEmail = Loadable(lazy(() => import('../pages/VerifyEmail')));
const SendReset = Loadable(lazy(() => import('../pages/SendReset')));
const ResetPassword = Loadable(lazy(() => import('../pages/ResetPassword')));
//const Features = Loadable(lazy(() => import('../pages/Features')));
const TwoFactorSetUp = Loadable(lazy(() => import('../pages/TwoFactorSetUp')));
const TwoFactorVerifyLink = Loadable(lazy(() => import('../pages/TwoFactorVerifyLink')));
const TwoFactorCheck = Loadable(lazy(() => import('../pages/TwoFactorCheck')));
const TwoFactorAuth = Loadable(lazy(() => import('../pages/TwoFactorAuth')));
const VerifySignIn = Loadable(lazy(() => import('../pages/VerifySignIn')));
const AuthorizeSignIn = Loadable(lazy(() => import('../pages/AuthorizeSignIn')));
const NeedAuthorization = Loadable(lazy(() => import('../pages/NeedAuthorization')));

const ChangeEmail = Loadable(lazy(() => import('../pages/ChangeEmail')));
const ForgotEmailReset = Loadable(lazy(() => import('../pages/ForgotEmailReset')));
const VerifyNewEmail = Loadable(lazy(() => import('../pages/VerifyNewEmail')));

// ==============================|| AUTH ROUTING ||============================== //

function LoginRoutes(props) {
    return (
        <Routes>
            <Route path="/" element={<MinimalLayout setToken={props.setToken}/>}>
                <Route exact path="/" element={<SignIn setToken={props.setToken} />}></Route>
                <Route exact path="/errPage" element={<ErrPage {...props} />}></Route>
                <Route exact path="/signup" element={<SignUp setToken={props.setToken} />}></Route>
                <Route exact path="/signup/checkemail" element={<CheckEmail setToken={props.setToken} />}></Route>
                <Route exact path="/signup/accountlocked" element={<AccountLocked setToken={props.setToken} />}></Route>
                <Route exact path="/signup/needauthorization" element={<NeedAuthorization setToken={props.setToken} />}></Route>
                <Route exact path="/signin" element={<AutoSignIn {...props} />}></Route>
                <Route exact path="/verifysignin" element={<VerifySignIn setToken={props.setToken}/>}></Route>
                <Route exact path="/authorizesignin" element={<AuthorizeSignIn setToken={props.setToken}/>}></Route>
                <Route exact path="/changePassword" element={<ChangePassword setToken={props.setToken} />}></Route>
                <Route exact path="/verifyaccount" element={<VerifyEmail setToken={props.setToken} />}></Route>
                <Route exact path="/reset" element={<SendReset setToken={props.setToken} />}></Route>
                <Route exact path="/resetpass" element={<ResetPassword setToken={props.setToken} />}></Route>
                <Route exact path="/twofactor" element={<TwoFactorVerifyLink setToken={props.setToken} />}></Route>
                <Route exact path="/twofactor/setup" element={<TwoFactorSetUp setToken={props.setToken} />}></Route>
                <Route exact path="/twofactor/check" element={<TwoFactorCheck setToken={props.setToken} />}></Route>
                <Route exact path="/twofactor/auth" element={<TwoFactorAuth setToken={props.setToken} />}></Route>
                <Route exact path="/changeemail" element={<ChangeEmail token={props.setToken}/>}></Route>
                <Route exact path="/forgotemail" element={<ForgotEmailReset token={props.setToken}/>}></Route>
                <Route exact path="/verifyforgotemail" element={<VerifyNewEmail setToken={props.setToken}/>}></Route>
            </Route>
        </Routes>
    )
};

export default LoginRoutes;