// assets
import { ChromeOutlined, QuestionOutlined,UploadOutlined, BarcodeOutlined, InfoCircleOutlined, FormOutlined } from '@ant-design/icons';

// icons
const icons = {
    ChromeOutlined,
    QuestionOutlined,
    UploadOutlined,
    BarcodeOutlined,
    InfoCircleOutlined,
    FormOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = {
    id: 'support',
    title: 'Support',
    type: 'group',
    children: [
        {
            id: 'documentation',
            title: 'Documentation',
            type: 'item',
            url: '/documentation',
            icon: icons.QuestionOutlined
        },
        {
            id: 'DocumentationConfig',
            title: 'Documentation Config',
            type: 'adminItem',
            url: '/admin/documentationConfig',
            icon: icons.QuestionOutlined
        },
        {
            id: 'auditTrail',
            title: 'Audit Trail',
            type: 'item',
            url: '/auditTrail',
            icon: icons.BarcodeOutlined,
            breadcrumbs: false
        },
        {
            id: 'userGuide',
            title: 'User Guide',
            type: 'item',
            url: '/userGuide',
            icon: icons.InfoCircleOutlined,
            breadcrumbs: false
        },
        {
            id: 'addProject',
            title: 'Add Project',
            type: 'item',
            url: '/addProject',
            icon: icons.InfoCircleOutlined,
        },
        {
            id: 'requestForms',
            title: 'Request Forms',
            type: 'item',
            url: '/requestForms',
            icon: icons.FormOutlined,
            breadcrumbs: false
        }
    ]
};

export default support;
