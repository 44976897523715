// project import
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';

import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// ==============================|| DRAWER CONTENT ||============================== //


const DrawerContent = () => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    

    return (<SimpleBar
        sx={{
            '& .simplebar-content': {
                display: 'flex',
                flexDirection: 'column'
            },
            backgroundColor: '#e3e3e3',
            overflow: 'auto',
            maxHeight: !matchDownMD ? 'calc(100vh - 62px)': 'calc(100vh - 25px)',
            minHeight: !matchDownMD ? 'calc(100vh - 62px)': 'calc(100vh - 25px)',
            // minHeight: 'calc(100vh - 91px)',
        }}
    >
        <Navigation />
    </SimpleBar>
)};

export default DrawerContent;
