// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    outputRow: {}, // Holds the row info to output
    formView: "DEFAULT", // Possible values: outPut, newProcess, editProcess, DEFAULT, any other string: Will go to default view 
    editRow: null // Holds the row needed to edit

};


const analysisScheduler = createSlice({
    name: 'analysisScheduler',
    initialState,
    reducers: {
        setOutputRow(state, action){
            if (!action.payload || action.payload == {}){
                state.outputRow = {}
            }
            else{
                Object.assign(state.outputRow, action.payload);
            }
        },
        setFormView(state, action){
            state.formView = action.payload.formView
        },
        setEditRow(state, action){
            state.editRow = action.payload.editRow
        },
        setToggleTableRefresh(state){
            state.toggleTableRefresh = !state.toggleTableRefresh
        },
        setToggleProcessRefresh(state){
            state.toggleProcessRefresh = !state.toggleProcessRefresh
        },
        setNewProcess(state){
            state.newProcess = !state.newProcess
        }
    }
})

export default analysisScheduler.reducer;

export const { setOutputRow, setFormView, setEditRow, setToggleTableRefresh, setToggleProcessRefresh, setNewProcess} = analysisScheduler.actions;

