import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import logo from 'assets/sonic_loading.png'

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import { GLOBAL_URL } from '../../../config';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import axios from 'axios'
import { setTokenExpired } from 'store/reducers/header';


// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ props, open, handleDrawerToggle }) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    const iconBackColor = 'grey.100';
    const iconBackColorOpen = 'grey.200';

    const { tokenExpired } = useSelector((state) => state.header);
    const { userName } = useSelector((state) => state.header);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        axios({
            method: "POST",
            url: GLOBAL_URL+"/api/logoutToken",
            data: {
                userName: userName
            }
        })
        .then((response) => {
            props.removeToken() 
            dispatch({type: "LOG_OUT"})
            navigate("/")
        }).catch((error) => {
            if(error.response) {
                console.log(error.response)
                console.log(error.response.status)
                console.log(error.response.headers)
            }
        })
    };

    // common header
    const mainHeader = (
        <Toolbar  sx={{height: '32px', minHeight: '32px'}}>
            <IconButton
                disableRipple
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                color="secondary"
                sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
            >
                {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </IconButton>
            <Collapse in={tokenExpired} sx={{ whiteSpace: "nowrap", position: "absolute", left: "40%"}}>
                <Alert 
                    id="tokenExpiredError" 
                    variant="filled" 
                    severity='error'
                    action={
                        <Button 
                            color="inherit" 
                            size="small"
                            sx={{
                                fontSize: 'inherit',
                                marginLeft: '50px',          
                                boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)'
                            }}
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    }
                >
                    Your token has expired. Please logout and login.
                </Alert>
            </Collapse> 
            <HeaderContent {...props}/>
        </Toolbar>
    );

    const mainHeaderStyled = (
        <Toolbar  sx={{height: '32px', minHeight: '32px'}}>
            <Link href="/dashboard">
                <img src={logo} className="App-header-alt-logo"/>
            </Link>
            {/* <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1, width: '200px' }} align="left">
                SecureGen Platform
            </Typography> */}
            <Collapse in={tokenExpired} sx={{ whiteSpace: "nowrap", position: "absolute", left: "40%"}}>
                <Alert 
                    id="tokenExpiredError" 
                    variant="filled" 
                    severity='error'
                    action={
                        <Button 
                            color="inherit" 
                            size="small"
                            sx={{
                                fontSize: 'inherit',
                                marginLeft: '50px',          
                                boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)'
                            }}
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    }
                >
                    Your token has expired. Please logout and login.
                </Alert>
            </Collapse> 
            <HeaderContent {...props}/>
        </Toolbar>
    );

    // app-bar params
    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            boxShadow: theme.customShadows.z1
        }
    };

    return (
        <>
            {!matchDownMD ? (
                <AppBarStyled open={open} {...appBar}>
                    {mainHeaderStyled}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    );
};

Header.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
};

export default Header;
