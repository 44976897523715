// assets
import {
    AppstoreAddOutlined,
    AntDesignOutlined,
    BarcodeOutlined,
    BgColorsOutlined,
    FontSizeOutlined,
    LoadingOutlined,
    EditOutlined,
    QuestionOutlined
} from '@ant-design/icons';

// icons
const icons = {
    FontSizeOutlined,
    BgColorsOutlined,
    BarcodeOutlined,
    AntDesignOutlined,
    LoadingOutlined,
    AppstoreAddOutlined,
    EditOutlined,
    QuestionOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

// adminItems are only visible to admin/user with admin permission
const admin = {
    id: 'admin',
    title: 'Admin Panel',
    type: 'group',
    children: [
        {
            id: 'admin-usersPortal',
            title: 'Users Portal',
            type: 'adminItem',
            url: '/admin/users',
            icon: icons.AntDesignOutlined,
            breadcrumbs: false
        },/*
        {
            id: 'DocumentationConfig',
            title: 'Documentation Config',
            type: 'item',
            url: '/admin/documentationConfig',
            icon: icons.QuestionOutlined
        },
        {
            id: 'admin-platformSettings',
            title: 'Platform Settings',
            type: 'adminItem',
            url: '/pricingAdmin',
            icon: icons.FontSizeOutlined,
            breadcrumbs: false
        },
        {
            id: 'admin-workStation',
            title: 'AWS Configurations',
            type: 'adminItem',
            url: '/admin/awsConfigurations',
            icon: icons.BgColorsOutlined,
            breadcrumbs: false
        },
        {
            id: 'admin-workstationConfig',
            title: 'Workstation Configurations',
            type: 'adminItem',
            url: '/admin/workConfig',
            icon: icons.EditOutlined,
            breadcrumbs: false
        },
        {
            id: 'admin-featuresConfig',
            title: 'Features Configurations',
            type: 'adminItem',
            url: '/admin/featuresConfig',
            icon: icons.EditOutlined,
            breadcrumbs: false
        },
        {
            id: 'admin-editHome',
            title: 'Edit Home Page',
            type: 'item',
            url: '/admin/EditHome',
            icon: icons.EditOutlined,
            breadcrumbs: false
        },*/
        {
            id: 'admin-executables',
            title: 'Executables',
            type: 'adminItem',
            url: '/admin/executables',
            icon: icons.EditOutlined,
            breadcrumbs: false
        },
        {
            id: 'admin-activitiesConfig',
            title: 'Activities Configurations',
            type: 'adminItem',
            url: '/admin/activitiesConfig',
            icon: icons.EditOutlined,
            breadcrumbs: false
        }
    ]
};

export default admin;
