// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    nextOpen: false,
    nextBtn: false,
    paymentInfo: {
        cardName: "",
        cardNumber: "",
        expMonth: "",
        expYear:"",
        cvv: ""
    }
};

// ==============================|| SLICE - HEADER ||============================== //

const checkout = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setPaymentInfo(state, action) {
            Object.assign(state.paymentInfo, action.payload);
        },
        setNextOpen(state, action) {
            state.nextOpen = action.payload.nextOpen;
        },
        setNextBtn(state, action) {
            state.nextBtn = action.payload.nextBtn;
        }
    }
});

export default checkout.reducer;

export const { setPaymentInfo, setNextOpen, setNextBtn } = checkout.actions;