// material-ui
import { Box } from '@mui/material';

// project import
import Profile from './Profile';
import Organization from './Organization';
import { useSelector } from 'react-redux';
import { forwardRef, useState, useEffect } from 'react'; 

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = (props) => {
    const { organization } = useSelector((state) => state.header);
    const [adminUser, setAdminUser] = useState(false);
    
    useEffect(()=>{
        if(organization==='ALL'){
            setAdminUser(true)
        }
    },[])

    return (
        <>
            <Box sx={{ width: '100%', ml: 1 }} />
            
            {adminUser &&<Organization {...props}/>}

            <Profile {...props}/>
            
        </>
    );
};

export default HeaderContent;