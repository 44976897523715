import { Outlet } from 'react-router-dom';
import Footer from '../MainLayout/Footer';


// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <>
        <Outlet />
        <Footer />
    </>
);

export default MinimalLayout;