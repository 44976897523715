// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    selection: [],
    fileSelection: "",
    selectionType: null,
    selectionName: null,
    selectedRowID: null,
    responseMsg: null,
    fileAlertSeverity: "error",
    fileAlertTimeout: false,
    dirManager: {}, 
    loader: false,
    emptyMsg: null,
    lastPath: '',
    currentFileSize: 0,
    totalOpenedSize: 0,
    currentPathRedux: ''
};

// ==============================|| SLICE - HEADER ||============================== //

const fileManager = createSlice({
    name: 'fileManager',
    initialState,
    reducers: {
        setSelection(state, action){
            state.selection = action.payload.selection
        },
        setFileSelection(state, action){
            state.fileSelection = action.payload.fileSelection
        },
        setSelectionType(state, action){
            state.selectionType = action.payload.selectionType
        },
        setSelectionName(state, action){
            state.selectionName = action.payload.selectionName
        },
        setSelectedRowID(state, action){
            state.selectedRowID = action.payload.selectedRowID
        },
        setEmptyMsg(state, action){
            state.emptyMsg = action.payload.emptyMsg
        },
        setLastPath(state, action){
            state.lastPath = action.payload.lastPath
        },
        setTotalOpenedSize(state, action){
            state.totalOpenedSize = action.payload.totalOpenedSize
        },
        setCurrentFileSize(state, action){
            state.currentFileSize = action.payload.currentFileSize
        },
        setResponseMsg(state, action){
            state.responseMsg = action.payload.responseMsg
        },
        setFileAlertSeverity(state, action){
            state.fileAlertSeverity = action.payload.fileAlertSeverity
        },
        setFileAlertTimeout(state, action){
            state.fileAlertTimeout = action.payload.fileAlertTimeout
        },
        setDirManager(state, action){
            state.dirManager = action.payload.dirManager
        }, 
        setLoader(state,action) {
            state.loader = action.payload.loader
        },
        setCurrentPathRedux(state, action){
            state.currentPathRedux = action.payload.currentPathRedux
        },
        setToggleRefresh(state){
            state.toggleRefresh = !state.toggleRefresh
        }
    }
});

export default fileManager.reducer;

export const { setSelection, setFileSelection, setSelectionType, 
    setSelectionName, setSelectedRowID, setEmptyMsg, setLastPath, 
    setResponseMsg, setFileAlertSeverity, setFileAlertTimeout, 
    setDirManager, setLoader, setTotalOpenedSize,setCurrentFileSize, 
    setCurrentPathRedux,setToggleRefresh} = fileManager.actions;