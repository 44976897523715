// material-ui
import { Box } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import { forwardRef, useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { GLOBAL_URL } from '../../../../config';
import AfterRequest from '../../../../components/AfterRequest';
import { setTokenExpired, setCurrOrganization, setOrganizationIndex } from 'store/reducers/header';
import { TOKEN_EXPIRE_MESSAGE } from '../../../../config';


// ==============================|| HEADER - CONTENT ||============================== //

const Organization = (props) => {
    const [organizationList, setOrganizationList] = useState([]);
        const { setNewToken } = AfterRequest();

    const dispatch = useDispatch();
    const { currOrganization, organizationIndex } = useSelector((state) => state.header);
    const [currCompany, setCurrCompany] = useState(currOrganization);

    useEffect(() => { //populate the software data array from the config file when the page loads to display contents of file on page
        function getOrganizations() {
            axios({
                method: "POST",
                url: GLOBAL_URL+"/api/getOrganizations",
                headers: {
                    Authorization: 'Bearer ' + props.token
                },
            })     
            .then(res => {
                setOrganizationList(res.data.organizations); //set the possible software dictionary for the dropdowns
                if (res.data.access_token) {
                    setNewToken(props,res.data.access_token) 
                }
                // if (res.data.newUserData){
                //     updateUserData(res.data.newUserData)
                // }
            }).catch((err) => {
                if(err.response){
                    if(err.response.data.msg){
                        if(err.response.data.msg == TOKEN_EXPIRE_MESSAGE){ 
                            dispatch(setTokenExpired({ tokenExpired: true }));
                        } 
                    } 
                    else {
                        if (err.response.data.access_token) {
                            setNewToken(props,err.response.data.access_token)
                        }
                        // if (err.response.data.newUserData){ 
                    //     updateUserData(err.response.data.newUserData) 
                    // }
                    }
                }
            })
        }
        getOrganizations();
    }, [])

    const handleOrganization = (event) => {
        setCurrCompany(event.target.value)
        dispatch(setCurrOrganization({ currOrganization: event.target.value }));
        dispatch(setOrganizationIndex({ organizationIndex: organizationList.indexOf(event.target.value) }))
        // console.log(organizationList)
        // console.log(organizationList.indexOf(event.target.value))
        // window.location.reload() 
        axios({
            method: "POST",
            url: GLOBAL_URL+"/api/setOrganization",
            headers: {
                Authorization: 'Bearer ' + props.token
            },
            data: {
                organization: event.target.value
            }
        })     
        .then(res => {
            // setOrganizationList(res.data.organizations); //set the possible software dictionary for the dropdowns
            if (res.data.access_token) {
                setNewToken(props,res.data.access_token) 
            }
             // if (res.data.newUserData){
      //   updateUserData(res.data.newUserData)
      // }
        }).catch((err) => {
            if(err.response){
                if(err.response.data.msg){
                    if(err.response.data.msg == TOKEN_EXPIRE_MESSAGE){ 
                        dispatch(setTokenExpired({ tokenExpired: true }));
                    } 
                } 
                else {
                    if (err.response.data.access_token) {
                        setNewToken(props,err.response.data.access_token)
                    }
                    // if (err.response.data.newUserData){ 
                    //     updateUserData(err.response.data.newUserData) 
                    // }
                }
            }
        })
    }

    return (
        <FormControl sx={{width: '15vw', textAlign: 'left'}}>
            <InputLabel id="demo-simple-select-label">Organization</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={'None'}
                value={currCompany}
                label="organization"
                onChange={e => handleOrganization(e)}
                // disabled={!adminUser || data.userName==='admin'}
            >
            {organizationList && organizationList.length > 0 && organizationList.map((item, index) => (
                <MenuItem key={index} value={item}>
                    {item}
                </MenuItem>
            ))}
                
            </Select>
        </FormControl>
    );
};

export default Organization;
